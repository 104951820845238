<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card>
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-media class="mb-1 mt-2 ml-2">
          <template #aside>
            <b-avatar
                ref="previewEl"
                :src="currentItem.avatar.url"
                size="90px"
                rounded
            />
          </template>
          <h4 class="mb-1 ml-1">{{ $t("Profile image") }}</h4>
          <div class="d-flex flex-wrap">
            <b-button
                class="ml-1"
                variant="success"
                @click="$refs.refInputEl.click()"
            >
              <input
                  ref="refInputEl"
                  type="file"
                  class="d-none"
                  @input="addAvatar"
              />
              <span v-if="currentItem.avatar && currentItem.avatar.url">
                {{ $t("Update") }}
              </span>
              <span v-else>
                {{ $t("Add") }}
              </span>
              <feather-icon icon="EditIcon" class="d-inline d-sm-none"/>
            </b-button>
            <b-button
                v-if="currentItem.avatar && currentItem.avatar.url"
                variant="danger"
                class="ml-1"
                @click="deleteAvatar"
            >
              <span class="d-none d-sm-inline">{{ $t("Remove") }}</span>
              <feather-icon icon="TrashIcon" class="d-inline d-sm-none"/>
            </b-button>
          </div>
        </b-media>

        <b-form
            class="p-2"
            @submit.stop.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >
          <b-row>
            <b-col md="6" xl="6" class="mb-1 mt-2">
              <b-form-group :label="$t('First Name')">
                <b-form-input
                    v-model="currentItem.firstname"
                    id="firstname"
                    :placeholder="$t('First Name')"
                />
              </b-form-group>
            </b-col>

            <b-col md="6" xl="6" class="mb-1 mt-2">
              <b-form-group :label="$t('Name')">
                <b-form-input
                    v-model="currentItem.name"
                    id="familytname"
                    :placeholder="$t('Name')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" xl="6" class="mb-1">
              <b-form-group label="Position">
                <b-form-input
                    v-model="currentItem.position"
                    id="position"
                    placeholder="Position"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group :label="$t('About me')">
            <b-form-textarea
                id="texterea-aboutme"
                md="12"
                rows="3"
                :placeholder="$t('About me')"
                v-model="currentItem.aboutme"
            />
          </b-form-group>

          <hr/>
          <b-form-group label="Slogan/Motto">
            <b-form-input
                v-model="currentItem.slogan"
                id="slogan"
                placeholder="Slogan/Motto"
            />
          </b-form-group>
          <b-form-group
              :label="$t('Birthday')"
              label-for="brithday"
          >
            <b-input-group class="mb-1">
              <cleave
                  v-model="currentItem.birthday_text"
                  class="form-control"
                  :raw="false"
                  placeholder="DD.MM.YYYY"
                  :options="{
                  date: true,
                  delimiter: '.',
                  datePattern: ['d', 'm', 'Y'],
                }"
                  v-on:blur="onBirthdayInput"
              />
              <b-input-group-append>
                <b-form-datepicker
                    button-variant="outline-primary"
                    left
                    size="sm"
                    v-model="currentItem.birthday"
                    show-decade-nav
                    button-only
                    aria-controls="birthday"
                    v-bind="labels[locale] || {}"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    @context="onBirthdayDatePickerChange"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <hr/>
          <h3 class="mb-1 mt-1">{{ $t("Contact") }}</h3>
          <b-row>
            <b-col md="6" xl="6" class="mb-1">
              <b-form-group :label="$t('Email')">
                <b-form-input
                    v-model="currentItem.email"
                    id="email"
                    :placeholder="$t('Email')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="6" class="mb-1">
              <b-form-group :label="$t('Phone')">
                <b-form-input
                    v-model="currentItem.phone"
                    id="phone"
                    :placeholder="$t('Phone')"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <hr/>

          <h3 class="mt-3 mb-2">{{ $t('Login') }}</h3>
          <b-row>
            <!-- password -->
            <b-col cols="6">
              <b-form-group :label="$t('Password')">
                <validation-provider
                    #default="{ errors }"
                    :label="$t('Password')"
                    :name="$t('Password')"
                    vid="Password"
                    rules="min:8|password"
                    class="mt-3"
                >
                  <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                        v-model="currentItem.password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :placeholder="$t('Password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- confirm password -->
            <b-col cols="6">
              <b-form-group :label="$t('Confirm Password')">
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Confirm Password')"
                    rules="confirmed:Password"
                >
                  <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                        v-model="currentItem.password_confirmation"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordconfirmFieldType"
                        :placeholder="$t('Confirm Password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          class="cursor-pointer"
                          :icon="passwordconfirmToggleIcon"
                          @click="togglePasswordConfirmVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12 mt-2">
              <b-button
                  variant="success"
                  type="submit"
                  @click.prevent="onSubmit"
              >
                {{ $t("Save") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </component>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
  BFormTextarea,
  BCardText,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormFile,
  BAvatar,
  BMedia,
  BInputGroupAppend,
  BInputGroup,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import {ref, onUnmounted, mounted} from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import {useToast} from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Cleave from 'vue-cleave-component'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import {required, alphaNum, email} from '@validations'
import moment from 'moment'
import {setDatePickerTranslation} from "@core/mixins/datePickerLocales";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    vSelect,
    BFormFile,
    BAvatar,
    BMedia,
    BInputGroupAppend,
    BInputGroup,
    Cleave,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility,setDatePickerTranslation],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordconfirmToggleIcon() {
      return this.passwordconfirmFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  data() {
    return {
      firstname: "",
      currentItem: {
        avatar: {
          url: "",
        },
        id: "",
        name: "",
        firstname: "",
        position: "",
        aboutme: "",
        slogan: "",
        birthday: "",
        birthday_text: '',
        references: "",
        testimonials: "",
        email: "",
        phone: "",
        password_confirmation: "",
        password: "",
      },

      loaded: true,
    };
  },

  methods: {
    updateItem() {
      this.helperUpdateItem(`/client/profile`, this.currentItem);
    },

    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },

    addAvatar(event) {
      if (event) event.preventDefault();

      const reader = new FileReader();
      let file = this.$refs.refInputEl.files[0];
      if (file) {
        reader.readAsDataURL(file);

        reader.onload = (evt) => {
          let base64 = evt.target.result;

          if (!this.currentItem.avatar) {
            this.currentItem.avatar = {};
          }

          this.currentItem.avatar.url = base64;

          this.uploadAvatar(file);
        };
      }
    },

    uploadAvatar(base64) {
      let formData = new FormData();
      formData.append("files", base64);

      this.$http
          .post(`/client/profile/avatarupload`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.debug()
            let userData = JSON.parse(localStorage.getItem("userData"));
            userData.profile.avatar.url = response.data.avatar.url;
            localStorage.setItem("userData", JSON.stringify(userData));

            this.$bvToast.toast(this.$i18n.t("Avatar uploaded successfully"), {
              title: this.$i18n.t("Success"),
              variant: "success",
              toaster: "b-toaster-bottom-right",
            });
          })
          .catch((e) => {
            this.$bvToast.toast(this.$i18n.t("Avatar not uploaded"), {
              title: this.$i18n.t("Error"),
              variant: "danger",
              toaster: "b-toaster-bottom-right",
            });
          });
    },
    deleteAvatar() {
      this.$http
          .delete(`/client/profile/avatarremove`)
          .then((response) => {
            this.currentItem.avatar.url = null;

            let userData = JSON.parse(localStorage.getItem("userData"));
            userData.profile.avatar.url = null;
            localStorage.setItem("userData", JSON.stringify(userData));

            this.$bvToast.toast(this.$i18n.t("Avatar deleted"), {
              title: this.$i18n.t("Success"),
              variant: "success",
              toaster: "b-toaster-bottom-right",
            });
          })
          .catch((e) => {
            this.$bvToast.toast(this.$i18n.t("Avatar not deleted"), {
              title: this.$i18n.t("Error"),
              variant: "danger",
              toaster: "b-toaster-bottom-right",
            });
          });
    },

    onSubmit() {
      this.updateItem();
    },

    onBirthdayDatePickerChange(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
      this.currentItem.birthday_text = moment(ctx.selectedYMD, 'YYYY-MM-DD')
          .format('DD.MM.YYYY')
    },
    onBirthdayInput() {
      let date = moment(this.currentItem.birthday_text, 'DD.MM.YYYY')

      if (date.isValid()) {
        this.currentItem.birthday = date.format('YYYY-MM-DD')
      } else {
        this.currentItem.birthday = null
        this.currentItem.birthday_text = ''
      }
    },
  },

  mounted() {
    this.helperGetItem(`/client/profile`);
  },
};
</script>
